import { createApp, h } from "vue"

document.addEventListener("DOMContentLoaded", () => {
    import("@/components/Frontend/CompatibilityPopup.vue").then(({ default: CompatibilityPopup }) => {
        const containerEl = document.querySelector("#compatibility-popup-container")

        const callCompatibilityPopup = (contentID) => {
            const app = createApp({
                render() {
                    return h(CompatibilityPopup, {
                        contentId: contentID,
                        showDownload: true,
                        onClose: () => {
                            app.unmount()
                            containerEl.innerHTML = "<div></div>"
                        },
                    })
                },
            })

            app.mount("#compatibility-popup-container > div")
        }

        document.querySelectorAll(".content-card-compatibility-button").forEach((elem) => {
            elem.addEventListener(
                "click",
                (ev) => {
                    ev.preventDefault()
                    callCompatibilityPopup(ev.target.dataset.contentId)
                },
                { passive: false },
            )
        })
    })
})
